import React from 'react'
import { Button } from 'reactstrap'
import Layout from '../components/layout'
const Style = () => (
  <Layout>
    <div>
      <div>
        <p className="text-muted">
          muted
          <br />
          Fusce dapibus, tellus ac cursus commodo, tortor mauris nibh.
        </p>
        <p className="text-primary">
          primary
          <br />
          Nullam id dolor id nibh ultricies vehicula ut id elit.
        </p>
        <p className="text-success">
          succes
          <br />
          Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
        </p>
        <p className="text-info">
          info
          <br />
          Maecenas sed diam eget risus varius blandit sit amet non magna.
        </p>
        <p className="text-warning">
          warning
          <br />
          Etiam porta sem malesuada magna mollis euismod.
        </p>
        <p className="text-danger">
          danger
          <br />
          Donec ullamcorper nulla non metus auctor fringilla.
        </p>
        <p className="text-white">
          white
          <br />
          Etiam porta sem malesuada ultricies vehicula.
        </p>
      </div>
      <div>
        <Button color="primary">primary</Button>{' '}
        <Button color="secondary">secondary</Button>{' '}
        <Button color="success">success</Button>{' '}
        <Button color="info">info</Button>{' '}
        <Button color="warning">warning</Button>{' '}
        <Button color="danger">danger</Button>{' '}
        <Button color="link">link</Button>
      </div>
      <div>
        <div className="p-3 mb-2 bg-primary text-white">.bg-primary</div>
        <div className="p-3 mb-2 bg-secondary text-white">.bg-secondary</div>
        <div className="p-3 mb-2 bg-success text-white">.bg-success</div>
        <div className="p-3 mb-2 bg-danger text-white">.bg-danger</div>
        <div className="p-3 mb-2 bg-warning text-dark">.bg-warning</div>
        <div className="p-3 mb-2 bg-info text-white">.bg-info</div>
        <div className="p-3 mb-2 bg-light text-dark">.bg-light</div>
        <div className="p-3 mb-2 bg-dark text-white">.bg-dark</div>
        <div className="p-3 mb-2 bg-white text-dark">.bg-white</div>
      </div>
    </div>
  </Layout>
)

export default Style
